<template>
  <div class="graph-line">
    <div class="title">{{ $t("charging-point.consumed-energy") }}</div>


<!--    <img src="@images/stat-line.png"  />-->
    <highcharts
        v-if="lineOptions"
        ref="graph"
        class="chart"
        :options="lineOptions"
    />

    <div class="filters">
      <v-chip link outlined class="f-year" :class="{active: type === 'year'}" @click="type = 'year'">{{ $t('year') }}</v-chip>
      <v-chip link outlined class="f-month" :class="{active: type === 'month'}" @click="type = 'month'">{{ $t('month') }}</v-chip>
      <v-chip link outlined class="f-week" :class="{active: type === 'week'}" @click="type = 'week'">{{ $t('week') }}</v-chip>
      <v-chip link outlined class="f-day" :class="{active: type === 'day'}" @click="type = 'day'">{{ $t('day') }}</v-chip>
    </div>
  </div>
</template>

<script>
import SessionRepository from "@repository/SessionRepository";
import dayjs from "dayjs";
import Tools from "@includes/tools";

export default {
  data() {
    return {
      type: 'year',
      lineOptions: null,
      usagesStats: []
    }
  },

  props: {
    values: Array
  },

  mounted() {
    this.loadData();
  },

  watch: {
    type: function () {
      const {data, categories} = this.getData()

      let color = '#778090'
      if (this.type === 'month') {
        color = '#DEAC3C'
      }
      if (this.type === 'week') {
        color = '#00897E'
      }
      if (this.type === 'day') {
        color = '#E53C22'
      }

      let chart = this.$refs.graph.chart

      chart.series[0].id = this.type
      chart.series[0].color = color
      chart.series[0].zones[0].color = color
      chart.series[0].zones[0].value = data.length - 1
      chart.xAxis[0].categories = categories

      let newData = []
      for(let i = 0; i < data.length; i++) {
        newData.push(data[i])
      }

      if(newData.length < 31)  {
        let lastValue = newData[newData.length - 1]
        for(let i = data.length; i < 31; i++) {
          newData.push(lastValue)
        }
      }

      chart.series[0].setData(newData, false, false)
      chart.xAxis[0].setExtremes(0, data.length - 1)
      chart.redraw()

    }
  },

  computed: {

    nbBornes() {
      return this.selected === null ? this.total : this.values[this.selected];
    },

    total() {
      return this.values[0] + this.values[1] + this.values[2]
    },



  },

  methods: {

    rand(min, max) { // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min)
    },


    loadData() {
      SessionRepository.usagesHistogram().then(response => {
        this.usagesStats = response
        this.lineOptions = this.graphOptions()
      }).catch(e => {

      })
    },


    getData() {

      let type = 'energy'
      let divider = 1000

      let categoriesYear = []
      let dataYear = []
      for(let i = 0; i<this.usagesStats.year.length; i++) {
        let m = this.usagesStats.year[i]
        categoriesYear.push(Tools.ucfirst(dayjs(m.month, 'YYYY-MM').format('MMMM')))
        dataYear.push(m[type]/divider)
      }


      let categoriesMonth = []
      let dataMonth = []
      for(let i = 0; i<this.usagesStats.month.length; i++) {
        let m = this.usagesStats.month[i]
        categoriesMonth.push(dayjs(m.day, 'YYYY-MM-DD').format('D MMM'))
        dataMonth.push(m[type]/divider)
      }

      let categoriesWeek = []
      let dataWeek = []
      for(let i = 0; i<this.usagesStats.week.length; i++) {
        let m = this.usagesStats.week[i]
        categoriesWeek.push(Tools.ucfirst(dayjs(m.day, 'YYYY-MM-DD').format('dddd')))
        dataWeek.push(m[type]/divider)
      }


      let categoriesDay = []
      let dataDay = []
      for(let i = 0; i<this.usagesStats.day.length; i++) {
        let m = this.usagesStats.day[i]
        categoriesDay.push(m.hour + 'h00')
        dataDay.push(m[type]/divider)
      }




      let data, categories
      switch (this.type) {
        case 'year':
          categories = categoriesYear
          data = dataYear
          break;

        case 'month':
          categories = categoriesMonth
          data = dataMonth
          break;

        case 'week':
          categories = categoriesWeek
          data = dataWeek
          break;

        case 'day':
          categories = categoriesDay
          data = dataDay
          break;
      }


      return {data, categories}
    },

    graphOptions() {


      const {data, categories} = this.getData()


      return {
        chart: {
          type: 'spline',
          backgroundColor: 'transparent',
          plotBackgroundColor: 'white',
          plotBorderColor: '#000000',
          plotBorderWidth: 1,
          height: 300,
          style: {

          },
          // scrollablePlotArea: {
          //   minWidth: 300,
          //   scrollPositionX: 1
          // }
        },
        title: {
          text: '',
        },
        subtitle: {
          text: '',
        },
        xAxis: {
          // gridLineWidth: 0,
          alternateGridColor: '#e9e9ea',
          categories,
          labels: {
            // rotation: 0,
            style: {
              fontFamily: 'Dosis',
              fontWeight: 400,
              fontSize: 12,
              color: '#8d9194',
            }
          }
        },
        yAxis: {
          title: {
            text: ''
          },
          // minorGridLineWidth: 0,
          gridLineDashStyle: 'Dot',
          gridLineWidth: 1,
          gridLineColor: '#757575',
          labels: {
            rotation: 0,
            format: '{value:1f} kWh',
            style: {
              fontFamily: 'Dosis',
              fontWeight: 600,
              fontSize: 12,
              color: '#72777b',
            }
          }
        },

        credits: false,
        // tooltip: {
        //   valueSuffix: ' kWh'
        // },
        plotOptions: {
          spline: {
            color: '#778090',
            lineWidth: 4,
            states: {
              hover: {
                lineWidth: 5
              }
            },
            marker: {
              enabled: false
            },
            // pointInterval: 3600000, // one hour
            // pointStart: Date.UTC(2018, 1, 13, 0, 0, 0)
          }
        },
        series: [{
          name: '',
          data,
          showInLegend: false,
          zoneAxis: 'x',
          zones: [{
            value: data.length,
            color: '#778090'
          }, {
            color: 'transparent'
          }]
        }],
        // navigation: {
        //   menuItemStyle: {
        //     fontSize: '10px'
        //   }
        // }
      }
    },
  },
}
</script>

<style lang="scss">
.graph-line {
  margin-top: 30px;
  padding: 0 45px;
  text-align: center;
  .title {
    text-align: center;
    color: #758093;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 30px;
  }
  img {
    max-width: 90%;
  }
  .filters {
    display: flex;
    justify-content: center;
    .v-chip {
      display: block;
      height: 40px;
      padding: 0 15px;
      color: #758093;
      border-color: #758093;
      border-radius: 22px;
      transition: all .3s ease-in;
      & + .v-chip {
        margin-left: 20px;
      }
      .v-chip__content {
        font-size: 20px;
        font-weight: bold;
      }
      &.f-year {
        &.active {
          background: #758093 !important;
        }
      }
      &.f-month {
        &:hover {
          color: #DEAC3C;
          border-color: #DEAC3C;
        }
        &.active {
          background: #DEAC3C !important;
        }
      }
      &.f-week {
        &:hover {
          color: #00897E;
          border-color: #00897E;
        }
        &.active {
          background: #00897E !important;
        }
      }
      &.f-day {
        &:hover {
          color: #E53C22;
          border-color: #E53C22;
        }
        &.active {
          background: #E53C22 !important;
        }
      }

      &.active {
        color: #fff;
        border-color: #fff;
        &:hover {
          color: #fff;
        }
      }
    }

  }
}

</style>