import Vue from 'vue'

class AccountRepository {

  forgotPassword(email) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/account/forgot-password',
        method: 'post',
        data: {
          email
        },
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  checkChangePasswordToken(token) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/account/check-change-password-token',
        method: 'post',
        data: {
          token
        },
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  changeForgotPassword(token, password) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/account/change-password',
        method: 'post',
        data: {
          forgotPasswordToken: token,
          password
        },
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  checkActivateToken(token) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/account/check-activation-token',
        method: 'post',
        data: {
          token
        },
      })
          .then(response => {
            resolve(response.data)
          }).catch(err => {
        reject(err)
      })
    })
  }

  activate(token, password) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/account/change-password',
        method: 'post',
        data: {
          activationToken: token,
          password
        },
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


}

export default new AccountRepository()