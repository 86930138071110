<template>
  <div class="forgot-password">


    <div class="shadow-box pa-4">


      <v-form v-if="step === 1"  ref="form" @submit.prevent="submit">
        <div class="logo">
          <img src="~@images/logo.svg" >
        </div>
        <v-row>
          <v-col>
            <div class="title">
              {{ $t('forgot-password.title') }}
            </div>
            <div class="description">
              {{ $t('forgot-password.description') }}
            </div>
            <v-text-field
                v-model="email"
                :label="$t('email')"
                :placeholder="$t('email')"
                persistent-placeholder
                solo
                :rules="[
                        commonRules.email,
                        commonRules.required,
                    ]"
            />
          </v-col>
        </v-row>


        <v-row class="btns">
          <v-col>
            <v-btn type="submit" text small to="/">
              {{ $t('back') }}
            </v-btn>
          </v-col>
          <v-col class="text-right">
            <v-spacer />
            <v-btn type="submit" class="white-bg" rounded outlined color="primary">
              <v-icon left>ico-yes</v-icon>
              {{ $t('forgot-password.send') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <div class="confirmation" v-if="step === 2">
        <v-row>
          <v-col>
            <div class="title">
              {{ $t('forgot-password.title') }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <b>{{ $t('forgot-password.confirmation-ok') }}</b>
            <div>{{ $t('forgot-password.confirmation-ok-description') }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-btn text to="/">
              {{ $t('back') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="car">
        <img src="@images/car.front.png" />
      </div>
    </div>

  </div>
</template>

<script>
import RulesMixin from "@mixins/RulesMixin";
import AccountRepository from "@repository/AccountRepository";

export default {
  mixins: [RulesMixin],

  data() {
    return {
      email: null,
      step: 1,
    }
  },

  methods: {
    submit() {
      if(this.$refs.form.validate()) {
        this.showLoading(true)
        AccountRepository.forgotPassword(this.email).then(result => {
          this.step = 2
          this.showLoading(false)
        }).catch(e => {
          this.$dialog.notify.error(e.response?.data?.message ?? e.message)
          this.showLoading(false)
        })
      }
    }
  }
}
</script>

<style lang="scss">
  .forgot-password {
    .shadow-box {
      width: 40.404vw;
      min-width: 400px;
      @media only screen and (max-width: 600px) {
        width: 100%;
        min-width: 100%;
        border: none;
        box-shadow: none;
      }
      align-items: center;
      .title {
        font-size: 30px;
        color: #5F6569;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .description {
        font-weight: 100;
        margin-bottom: 10px;
      }
      form {
        position: relative;
        z-index: 2;
        padding: 20px;
        width: 23.232vw;
        @media only screen and (max-width: 600px) {
          width: 100%;
        }
        .logo {
          text-align: center;
          margin-bottom: 20px;
          img {
            width: 100%;
          }
        }
      }
      .confirmation {
        font-weight: 100;
      }
      .car {
        position: absolute;
        right: 0;
        bottom: 110px;
        z-index: 0;
        @media only screen and (max-width: 600px) {
          display: none;
        }
        img {
          width: 40vw;
        }
      }
    }
  }
</style>