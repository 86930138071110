<template>
  <div class="forgot-password">


    <div class="shadow-box pa-4">

      <div class="confirmation" v-if="step === 0">
        <div class="logo">
          <img src="~@images/logo.svg" >
        </div>
        <v-row>
          <v-col>
            <div class="title">
              {{ $t('activation.title') }}
            </div>
            <div class="text-center">
              <v-progress-circular indeterminate color="primary" size="60" v-if="!tokenNotValid" />
              <div v-else>
                <div>
                  {{ $t('not-valid-link') }}
                  <v-row class="mt-1">
                    <v-col class="text-center">
                      <v-btn text small to="/">
                        {{ $t('back') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>

              </div>
            </div>
          </v-col>
        </v-row>
      </div>

      <v-form v-if="step === 1"  ref="form" @submit.prevent="submit">
        <div class="logo">
          <img src="~@images/logo.svg" >
        </div>
        <v-row>
          <v-col>
            <div class="title">
              {{ $t('activation.title') }}
            </div>
            <div class="description">
              {{ $t('activation.description') }}
            </div>
            <v-text-field
                v-model="password"
                :label="$t('password')"
                :placeholder="$t('password')"
                type="password"
                :name="`p_${Math.random()}`"
                persistent-placeholder
                solo
                :rules="[
                    commonRules.required,
                    commonRules.password,
                ]"
            />

            <v-text-field
                v-model="passwordConfirmation"
                :label="$t('account.password-confirm')"
                :placeholder="$t('account.password-confirm')"
                :name="`pc_${Math.random()}`"
                persistent-placeholder
                solo
                type="password"
                :rules="[
                    commonRules.required,
                    v => v === password || $t('invalid-confirm-password')
                ]"
            />
          </v-col>
        </v-row>

        <div class="description">
          {{ $t('password-complexity') }}
        </div>


        <v-row class="btns">
          <v-col class="text-right">
            <v-spacer />
            <v-btn type="submit" class="white-bg" rounded outlined color="primary">
              {{ $t('activation.send') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <div class="confirmation" v-if="step === 2">
        <v-row>
          <v-col>
            <div class="title">
              {{ $t('activation.title') }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <b>{{ $t('activation.confirmation-ok') }}</b>
            <div>{{ $t('activation.confirmation-ok-description') }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-btn type="submit" class="white-bg" rounded outlined color="primary" to="/">
              {{ $t('login.login') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="car">
        <img src="@images/car.front.png" />
      </div>
    </div>

  </div>
</template>

<script>
import RulesMixin from "@mixins/RulesMixin";
import AccountRepository from "@repository/AccountRepository";

export default {
  mixins: [RulesMixin],

  data() {
    return {
      password: null,
      passwordConfirmation: null,
      step: 1,
      tokenNotValid: false,
    }
  },

  mounted() {
    this.checkToken()
  },

  watch: {
    password() {
      this.$refs.form.validate()
    },

    passwordConfirmation() {
      this.$refs.form.validate()
    }
  },

  methods: {
    checkToken() {
      this.showLoading(true)
      AccountRepository.checkActivateToken(this.$route.query.token).then(() => {
        this.step = 1
        this.showLoading(false)
      }).catch(e => {
        this.showLoading(false)
        this.tokenNotValid = true
      })

    },

    submit() {
      if(this.password !== this.passwordConfirmation) {
        this.$dialog.notify.error(this.$t('invalid-confirm-password'))
        return
      }
      if(this.$refs.form.validate()) {
        this.showLoading(true)
        AccountRepository.activate(this.$route.query.token, this.password).then(() => {
          this.step = 2
          this.showLoading(false)
        }).catch(e => {
          this.$dialog.notify.error(e.response?.data?.message ?? e.message)
          this.showLoading(false)
        })
      }
    }
  }
}
</script>

<style lang="scss">
  .forgot-password {
    .shadow-box {
      width: 40.404vw;
      min-width: 400px;
      @media only screen and (max-width: 600px) {
        width: 100%;
        min-width: 100%;
        border: none;
        box-shadow: none;
      }
      align-items: center;
      .title {
        font-size: 30px;
        color: #5F6569;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .description {
        font-weight: 100;
        margin-bottom: 10px;
      }
      form {
        position: relative;

        z-index: 2;
        padding: 20px;
        width: 23.232vw;
        @media only screen and (max-width: 600px) {
          width: 100%;
        }
      }
      .logo {
        width: 23.232vw;
        @media only screen and (max-width: 600px) {
          width: 100%;
        }
        text-align: center;
        margin-bottom: 20px;
        img {
          width: 100%;
        }
      }
      .confirmation {
        font-weight: 100;
      }
      .car {
        position: absolute;
        right: 0;
        bottom: 110px;
        z-index: 0;
        @media only screen and (max-width: 600px) {
          display: none;
        }
        img {
          width: 40vw;
        }
      }
    }
  }
</style>