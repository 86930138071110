<template>
  <div class="import-tariff-gireve pa-10">
    <title-page icon="ico-carplug">
      {{ $t('gireve.import-tariffs') }}
    </title-page>


    <v-row>
      <v-col class="description" v-html="$t('gireve.import-tariffs-description')"></v-col>
    </v-row>

    <template v-if="step === 1">
      <v-row>
        <v-col cols="12" md="10">
          <v-file-input
              v-model="file"
              :label="$t('gireve.select-file')"
              accept=".zip"
          ></v-file-input>
        </v-col>
        <v-col class="text-center">
          <v-btn
              @click="importTariffs"
              :loading="loading"
              :disabled="!file || loading"
          >
            {{ $t('gireve.import') }}
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <template v-if="step === 2">

      <v-row>
        <v-col>
          <v-progress-linear
              :value="progress"
              :indeterminate="progress === 0"
              :color="progress < 100 ? 'primary' : 'success'"
              background-color="primary lighten-4"
              active
              height="20"
          >
            <span class="progress-text">
              {{ progress }} %
            </span>
          </v-progress-linear>
        </v-col>
      </v-row>

      <v-row v-if="!loading">
        <v-col class="text-right">
          <v-btn small @click="step = 1">
            {{ $t('back') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="lastGroup">
        <v-col>
          <v-list dense flat class="white mb-6 elevation-1">
            <v-list-item dense>
              <v-list-item-action>
                <v-progress-circular v-if="loading" indeterminate size="20" width="2"  class="ml-3" color="primary" />
                <v-icon v-else right color="success">mdi-check</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ lastGroup }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-card v-if="logsFormatted.length > 0">
            <v-card-text>
              <v-data-table
                  dense
                  disable-sort
                  disable-pagination
                  hide-default-footer
                  :items="logsFormatted"
                  :headers="[
                        {text: $tc('operator.operator'), value: 'operatorId'},
                        {text: $t('gireve.created'), value: 'created'},
                        {text: $t('gireve.updated'), value: 'updated'},
                        {text: $t('gireve.ignored'), value: 'ignored'},
                    ]"
              >

              </v-data-table>
            </v-card-text>
          </v-card>

        </v-col>
      </v-row>
    </template>



  </div>
</template>

<script>
import GireveRepository from "@repository/GireveRepository";
import { v4 as uuidv4 } from 'uuid';
import {EventBus} from "@includes/EventBus";


export default {
  data() {
    return {
      step: 1,
      file: null,
      loading: false,
      requestId: null,
      importLogs: [],
    }
  },

  destroyed() {
    if(this.requestId) {
      EventBus.$off(`on-import-gireve-tariff::${this.requestId}`)
    }
  },

  computed: {

    allGroups() {
      const allGroups = this.importLogs.filter(log => log.type === 'summary').map(log => log.value)
      if(allGroups.length > 0) {
        return allGroups[0]
      }
      return [];
    },

    progress() {
      const foundedIndex = this.allGroups.findIndex(log => log === this.lastGroup)
      if(this.allGroups.length > 0 && foundedIndex > -1) {
        return Math.round((this.loading ? foundedIndex : foundedIndex + 1) * 100 / this.allGroups.length)
      }
      return 0
    },

    treatedGroups() {
      return this.importLogs.filter(log => log.type === 'title').map(log => log.value);
    },

    lastGroup() {
      let data = this.treatedGroups.slice(-1);
      if(data.length > 0) {
        return data[0];
      }
      return null
    },

    logsFormatted() {
      if(!this.importLogs.length)
        return []

      const data = []

      let groupName = null
      for(let [index, log] of this.importLogs.entries()) {
        if(log.type === 'title') {
          groupName = log.value
        }

        if(groupName) {
          if(log.type === 'result') {
            const keys = Object.keys(log.value)
            data.push({
              groupName,
              operatorId: keys[0],
              ...log.value[keys[0]]
            })
          }
        }
      }

      return data
    }
  },

  methods: {
    importTariffs() {

      if(this.requestId) {
        EventBus.$off(`on-import-gireve-tariff::${this.requestId}`)
      }

      this.requestId = uuidv4()
      this.importLogs = []
      EventBus.$on(`on-import-gireve-tariff::${this.requestId}`, ({payload}) => {
        this.importLogs.push(payload)
      })


      this.step = 2;

      this.loading = true;
      GireveRepository.importTariffs(this.file, this.requestId).then(data => {
        this.loading = false;
        this.file = null;
      }).catch(error => {
        this.notifyError(error)
        this.step = 1;
        this.loading = false;
        this.file = null;
      })
    },


  }
}
</script>


<style lang="scss">
.import-tariff-gireve {
    .description {
      font-weight: 400;
    }
    tbody {
      tr:hover {
        background-color: transparent !important;
      }
    }
    .progress-text {
      font-size: 12px;
      font-weight: 500;
      color: white;
    }
}
</style>
