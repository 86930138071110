<template>
  <div class="import-cdr pa-10">
    <title-page icon="mdi-microsoft-excel">
      {{ $t('meter-device.import.title') }}
    </title-page>


    <template v-if="step === 1">
      <v-row>
        <v-col cols="12" md="10">
          <v-select
              :label="$t('meter-device.import.type')"
              :items="types"
              v-model="type"
          />
        </v-col>
      </v-row>
      <v-row v-if="this.type && this.type.length!==0">
        <v-col cols="12" md="10">
          <v-file-input
              v-model="file"
              :label="$t('meter-device.import.select-file')"
              accept=".xlsx,.csv"
          ></v-file-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
              @click="importCdrs"
              :loading="loading"
              :disabled="!file || loading"
              class="white-bg" rounded outlined color="primary">
            {{ $t('meter-device.import.parse-file') }}
          </v-btn>
        </v-col>
      </v-row>
      <br>
      <template v-if="totalItems >  0">
        <v-card
            flat
        >
          <v-card-title>
            <v-icon color="green">mdi-check-circle</v-icon>
            {{ $t('import-success') }}
          </v-card-title>
          <v-card-subtitle>

            {{ $t('meter-device.import.success', {cdrImported: totalItems}) }}
          </v-card-subtitle>

          <v-card-text>
            <v-data-table
                :headers="headersLines"
                :items="linesCdrValid"
                :loading="loading"
                item-value="name"
                :items-length="totalItems"
                density="compact"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </template>

    </template>

    <template v-if="step === 2">

      <v-row>
        <v-col>
          <v-progress-linear
              indeterminate
              background-color="primary lighten-4"
              active
              height="20"
              v-if="loading"
          >
            Loading...
          </v-progress-linear>
        </v-col>
      </v-row>
    </template>
    <template v-if="step === 2 && displayImportError">
      <v-card
          v-for="(card, index) in linesErrors"
          :key="index"
          class="mx-auto"
      >
        <v-card-title>
          <v-icon color="red">mdi-alert-circle</v-icon>
          {{ $t('meter-device.import.error.' + card.error) }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('meter-device.import.error.' + card.message) }}
        </v-card-subtitle>
        <div>
          <v-card-text>
            <v-row class="mb-6"
                   no-gutters
                   style="border:1px solid #cecece"
            >
              <template v-for="(item, index) in card.data">
                <v-col :key="index" v-if="card.action==='add'" cols="2"
                       class="error_cells">
                  <router-link target="_blank"
                               :to="{ name: card.routerLink, query: {externalId: item, type: 'smart-cable'}}">
                    <span class="identifier">{{ item }}</span>
                  </router-link>
                </v-col>
                <v-col :key="index" v-else-if="card.action==='edit'" cols="2" class="error_cells">
                  <router-link :to="{name: card.routerLink, params: {id: index}}" target="_blank">
                    <span class="identifier">{{ item }}</span>
                  </router-link>
                </v-col>
                <v-col :key="index" v-else cols="2"
                       class="error_cells">
                  <span class="identifier">{{ item }}</span>
                </v-col>
              </template>
            </v-row>
          </v-card-text>
        </div>
        <div v-if="index !== linesErrors.length - 1" style="margin-bottom: 20px;"></div>

      </v-card>
      <br>
      <v-btn @click="step = 1">
        {{ $t('back') }}
      </v-btn>
    </template>
    <template v-if="step === 3">
      <div>
        <v-tabs
            v-model="selectedTab"
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#valid_lines"> {{ $t('meter-device.import.valid_lines') }}</v-tab>
          <v-tab href="#exclud_lines">{{ $t('meter-device.import.invalid_lines') }}</v-tab>
        </v-tabs>
        <!-- Valid results CDR for insertion-->
        <v-data-table
            v-if="selectedTab ===  'valid_lines'"
            :headers="headersLines"
            :items="linesCdrValid"
            :loading="loading"
            :items-length="totalItems"
            density="compact"
            class="white"
        >
          <template #item.externalMdName="{item}">
            <router-link :to="{ name: 'meter-device.detail', params: { id: item.meterDeviceId }}" target="_blank">
              <v-chip class="ma-2 white--text" variant="outlined" color="green">
                {{ item.externalMdName }}
              </v-chip>
            </router-link>
          </template>
          <template #item.startTime="{value}">
            {{ formatDate(value, $t('format_datetime')) }}
          </template>
          <template #item.stopTime="{value}">
            {{ formatDate(value, $t('format_datetime')) }}
          </template>
          <template #item.user="{ item }">

            <v-chip
                v-if="item.user.toLowerCase() !==item.userDb.toLowerCase() " style="color:red"
                class="ma-2"
                color="danger"
                variant="outlined"
            >
              <router-link :to="{ name: 'meter-device.detail', params: { id: item.meterDeviceId }}" target="_blank">
                <v-tooltip
                    bottom
                    color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                              <span
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                  {{ item.user }}

                              </span>
                  </template>
                  {{ $t('meter-device.open-form') }}
                </v-tooltip>

              </router-link>
              <v-icon icon="mdi-account-outline" end></v-icon>
            </v-chip>
            <v-chip v-else
                    class="ma-2"
                    color="success"
                    variant="outlined"
            >
              <v-icon icon="mdi--account-outline" start></v-icon>
              {{ item.user }}
            </v-chip>
          </template>

          <template #item.energy="{value}">
            {{ value }} KWh
          </template>
          <template #item.duration="{ value }">
            {{ Math.floor(value / 3600) }}h{{ Math.floor((value % 3600) / 60) }}m{{ value % 60 }}s
          </template>
        </v-data-table>
        <!-- Excluded results CDR because ExternalId for CDR already exists-->
        <v-data-table
            v-if="selectedTab ===  'exclud_lines'"
            :headers="headersLines"
            :items="linesCdrExcludes"
            :loading="loading"
            :items-length="totalItems"
            density="compact"
            class="white"
        >
          <template #item.externalMdId="{value}">
            <v-chip
                class="ma-2 white--text"
                variant="outlined"
                color="red"
            >
              {{ value }}
            </v-chip>
          </template>
          <template #item.startTime="{value}">
            {{ formatDate(value, $t('format_datetime')) }}
          </template>
          <template #item.stopTime="{value}">
            {{ formatDate(value, $t('format_datetime')) }}
          </template>
          <template #item.energy="{value}">
            {{ value }} KWh
          </template>
          <template #item.duration="{ value }">
            {{ Math.floor(value / 3600) }}h{{ Math.floor((value % 3600) / 60) }}m{{ value % 60 }}s
          </template>
        </v-data-table>
        <v-btn
            v-if="totalItems > 0"
            @click="createCrdInZMS"
            class="white-bg" rounded outlined color="primary">
          {{ $t('meter-device.import.import-cdrs') }}
        </v-btn>
        <v-btn v-else @click="step = 1">
          {{ $t('back') }}
        </v-btn>
      </div>
    </template>
  </div>
</template>

<script>
import MeterDeviceRepository from "@repository/MeterDeviceRepository";
import {v4 as uuidv4} from 'uuid';
import {EventBus} from "@includes/EventBus";


export default {
  data() {
    return {
      step: 1,
      file: null,
      loading: false,
      requestId: null,
      importLogs: [],
      types: ['Powerdale', 'Ohme'],
      type: null,
      displayImportError: false,
      headersError: [
        {text: this.$t('error'), value: "title"},
        {text: this.$t('description'), value: "message"},
      ],
      linesErrors: [],
      headersLines: [
        {text: this.$tc('meter-device.meter-device', 2), value: "externalMdName"},
        {text: this.$t('meter-device.external-reference'), value: "externalMdId"},
        {text: this.$t('start-time'), value: "startTime"},
        {text: this.$t('stop-time'), value: "stopTime"},
        {text: this.$t('charging-point.consumed-energy'), value: "energy"},
        {text: this.$t('user.users') + ' XLSX', value: "user"},
        {text: this.$t('user.users') + ' ZMS', value: "userDb"},
        {text: this.$t('duration'), value: "duration"},
        {text: this.$t('charging-point-id'), value: "meterDeviceId"},
        {text: this.$t('organization-id'), value: "organizationId"},
      ],
      linesCdrValid: [],
      linesCdrExcludes: [],
      totalItems: 0,
      expanded: [],
      localSelectedTab: 0,
      show: false,
    }
  },


  computed: {
    selectedTab: {
      set(tab) {
        this.localSelectedTab = tab
        this.$router.replace({query: {...this.$route.query, tab}}) //met l'onglet selectionné dans l'url
      },
      get() {
        return this.$route.query.tab
      },
    },
  },

  methods: {
    toggleCard(index) {
      this.linesErrors[index].show = !this.linesErrors[index].show;
    },
    importCdrs() {
      this.step = 2;
      this.loading = true;
      this.linesErrors = [];
      MeterDeviceRepository.importCdrs(this.type, this.file).then(data => {
        if (data[0].success) {
          this.linesCdrValid = data[0].data.valid
          this.linesCdrExcludes = data[0].data.excluded
          this.totalItems = data[0].data.valid.length
          this.step = 3;
        } else {//Error found
          let errorsFound = data[0].errors
          for (let key in errorsFound) {
            errorsFound[key].show = false
            this.linesErrors.push(errorsFound[key])
          }
          console.log(this.linesErrors)
          this.displayImportError = true
        }
        this.loading = false;
        this.file = null;
      }).catch(error => {
        this.notifyError(error)
        this.step = 1;
        this.loading = false;
        this.file = null;
      })
    },
    createCrdInZMS() {
      this.loading = true;
      MeterDeviceRepository.createCdrsInZMS(this.linesCdrValid, this.type).then(data => {
        this.loading = false;
        this.$dialog.notify.success('CDR created successfully')
        this.type = null;
        this.step = 1;
      }).catch(error => {
        this.notifyError(error)
        this.loading = false;

        this.step = 1;
      })
    },
  }
}
</script>


<style lang="scss">
.import-cdr {
  .description {
    font-weight: 400;
  }

  tbody {
    tr:hover {
      background-color: transparent !important;
    }
  }
}

.custom-elevation {
  border: 1px solid #53B257; /* Couleur verte pour la bordure */
  border-radius: 4px; /* Facultatif : arrondir les coins de la bordure */
}


.fill-width {
  width: 100%;
}

.error_cells {
  padding: 5px;
  border-right: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  text-align: center
}

</style>