var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forgot-password"},[_c('div',{staticClass:"shadow-box pa-4"},[(_vm.step === 0)?_c('div',{staticClass:"confirmation"},[_vm._m(0),_c('v-row',[_c('v-col',[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('activation.title'))+" ")]),_c('div',{staticClass:"text-center"},[(!_vm.tokenNotValid)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"60"}}):_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.$t('not-valid-link'))+" "),_c('v-row',{staticClass:"mt-1"},[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{attrs:{"text":"","small":"","to":"/"}},[_vm._v(" "+_vm._s(_vm.$t('back'))+" ")])],1)],1)],1)])],1)])],1)],1):_vm._e(),(_vm.step === 1)?_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@images/logo.svg")}})]),_c('v-row',[_c('v-col',[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('activation.title'))+" ")]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t('activation.description'))+" ")]),_c('v-text-field',{attrs:{"label":_vm.$t('password'),"placeholder":_vm.$t('password'),"type":"password","name":`p_${Math.random()}`,"persistent-placeholder":"","solo":"","rules":[
                  _vm.commonRules.required,
                  _vm.commonRules.password,
              ]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"label":_vm.$t('account.password-confirm'),"placeholder":_vm.$t('account.password-confirm'),"name":`pc_${Math.random()}`,"persistent-placeholder":"","solo":"","type":"password","rules":[
                  _vm.commonRules.required,
                  v => v === _vm.password || _vm.$t('invalid-confirm-password')
              ]},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}})],1)],1),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t('password-complexity'))+" ")]),_c('v-row',{staticClass:"btns"},[_c('v-col',{staticClass:"text-right"},[_c('v-spacer'),_c('v-btn',{staticClass:"white-bg",attrs:{"type":"submit","rounded":"","outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('activation.send'))+" ")])],1)],1)],1):_vm._e(),(_vm.step === 2)?_c('div',{staticClass:"confirmation"},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('activation.title'))+" ")])])],1),_c('v-row',[_c('v-col',[_c('b',[_vm._v(_vm._s(_vm.$t('activation.confirmation-ok')))]),_c('div',[_vm._v(_vm._s(_vm.$t('activation.confirmation-ok-description')))])])],1),_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{staticClass:"white-bg",attrs:{"type":"submit","rounded":"","outlined":"","color":"primary","to":"/"}},[_vm._v(" "+_vm._s(_vm.$t('login.login'))+" ")])],1)],1)],1):_vm._e(),_vm._m(1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@images/logo.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"car"},[_c('img',{attrs:{"src":require("@images/car.front.png")}})])
}]

export { render, staticRenderFns }