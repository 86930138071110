<template>
  <div class="shadow-box">
    <div class="title">
      {{ $t('alerts-log') }}
    </div>



    <v-list>
      <template  v-for="(issue, n) in issues">

        <v-divider
            v-if="n > 0"
            :key="'divider-' + n"
        ></v-divider>

        <v-list-item three-line :key="'item-' + n" :class="{active: issue.status !== 'CLOSED' && issue.status !== 'RESOLVED'}" :to="{name: 'issues.detail', params: {id: issue.id}}">
          <v-list-item-content>
            <v-list-item-subtitle class="date">
              {{ formatDate(issue.createdAt, $t('format_datetime')) }}
            </v-list-item-subtitle>
            <v-list-item-title>
              {{ issueSubject(issue) }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ issue.evse.address.city }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>

            <v-list-item-action-text class="statut">
              <div class="text-center">
                {{ $t('issues.status') }}
              </div>
              <v-chip dark :color="issueStatusColor(issue)">{{ $t('enums.issue-statues.' + issue.status) }}</v-chip>
            </v-list-item-action-text>

          </v-list-item-action>
        </v-list-item>

      </template>

      <v-list-item :to="{name: 'issues'}" class="justify-end">
        {{ $t('show-all-issues') }}
      </v-list-item>

    </v-list>
  </div>
</template>

<script>
import IssueRepository from "@repository/IssueRepository";

export default {
  data() {
    return {
      loading: false,
      issues : []
    }
  },

  mounted() {
    this.loadData()
  },

  methods: {
    loadData() {
      this.loading = true
      IssueRepository.search({
        status: ['OPENED', 'NEW', 'IN_PROGRESS']
      }).then(result => {
        this.issues = result.items
        this.loading = false
      }).catch(error => {
        this.$dialog.notify.error(error.response?.data?.message ?? error.message)
        this.loading = false
      })
    },

  }
}
</script>

<style lang="scss">

</style>