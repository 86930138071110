<template>
  <div class="shadow-box contact-box">
    <div class="title">
      <v-icon x-large>mdi-face-agent</v-icon>
      {{ $t('phone-support') }}
    </div>
    <div  class="infos">
      <table>
        <tr>
          <td class="label">
            France
          </td>
          <td>
            <a href="tel:0033252072597">
              +33 (0) 2 52 07 25 97
            </a>
          </td>
        </tr>
        <tr>
          <td class="label">
            Benelux
          </td>
          <td>
            <a href="tel:003210848240">
              +32 (0) 10 84 82 40
            </a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
};
</script>

<style lang="scss">
.contact-box {
  padding-bottom: 10px;

  .title {
    margin-bottom: 5px;
  }

  .infos {
    table {
      margin: 0 auto;
      color: #727272;
      tr {
        td {
          padding: 5px 0;
          vertical-align: middle;
          a {
            text-decoration: none;
            font-size: 18px;
          }
          &.label {
            padding-right: 20px;
            text-align: right;
            font-weight: 500;
            font-size: 20px;
            img {
              //display: none;
              width: 20px;
            }
          }
        }
      }
    }
  }

}
</style>
