import Vue from 'vue'

class UserRepository {

  save(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/user',
        method: 'put',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(e => {
        reject(e)
      })
    })
  }
}

export default new UserRepository()