<template>
  <div class="dashboard-org pa-10">
    <v-row>


      <v-col class="main">
        <div class="logo">
          <img src="@images/logo.svg" />
        </div>

        <div class="bloc search">
          <div class="title">
            Rechercher un client, une borne…
          </div>
          <div class="content">
            <v-autocomplete
              v-model="foundedItem"
              placeholder="Rechercher une société, numéro de borne, etc…"
              prepend-inner-icon="ico-magnify"
              hide-details
              :menu-props="{ 'contentClass': 'dashboard-autocomplete' }"
              no-filter
              hide-selected
              :search-input.sync="search"
              :items="searchResult"
              solo
            >
              <template #item="{item}">
                <v-list-item-avatar>
                  <v-icon v-if="item.type === 'customer'">ico-carplug</v-icon>
                  <v-icon v-if="item.type === 'charging-point'">ico-charging-point</v-icon>
                  <v-icon v-if="item.type === 'organization'">ico-organization-menu</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title> {{ item.name }} </v-list-item-title>
                  <v-list-item-subtitle> {{ item.organization ?? item.accountNumber }} </v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <template #selection="{item}">
                {{ item.name }}
              </template>
            </v-autocomplete>
          </div>
        </div>

        <div class="bloc options">
          <div class="title">
            VOS OPTIONS
          </div>
          <div class="content">
            <div class="btns">
              <v-btn :to="{name: 'charging-points.add'}">
                <v-icon size="40">ico-charging-point-add</v-icon>
                <span>
                    Ajouter une borne
                  </span>
              </v-btn>
              <v-btn :to="{name: 'customer.add'}">
                <v-icon size="40">ico-user-add</v-icon>
                <span>
                    Ajouter un client
                  </span>
              </v-btn>
            </div>
          </div>
        </div>

      </v-col>

      <v-col  class="alerts">
        <alerts />

        <div class="car">
          <img src="@images/car.svg" />
        </div>
      </v-col>

      <v-col class="stats">
        <div class="shadow-box">
          <div class="title">
            Statut des bornes
          </div>


          <graph-charging-point-circle
              :values="nbBorneStats"
          />

          <graph-usage-line
              :values="nbBorneStats"
          />

        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import SharedRepository from "@repository/SharedRepository";
import GraphChargingPointCircle from "@blocks/GraphChargingPointCircle";
import GraphUsageLine from "@blocks/GraphUsageLine";
import Alerts from "@pages/Dashboard/Alerts";

export default {
  components: {
    Alerts,
    GraphChargingPointCircle,
    GraphUsageLine,
  },
  data() {
    return {
      nbBorneStats: [
        1907,
        326,
        54,
      ],

      search: null,
      foundedItem: null,
      searchResult: [],

    }
  },

  watch: {
    foundedItem(val) {
      if(val) {
        if(val.type === 'charging-point') {
          this.$router.push({name: 'charging-points.detail', params: {id: val.id}})
        }
        if(val.type === 'customer') {
          this.$router.push({name: 'customer.detail', params: {id: val.id}})
        }
      }
    },

    search() {
      this.searchForAllType()
    },
  },

  created() {

  },

  computed: {




  },

  methods: {


    searchForAllType()  {
      console.log(this.search)
      SharedRepository.search(this.search).then(results => {
        this.searchResult = results
      }).catch(err => {
        this.$dialog.notify.error(this.$t(err.response?.data?.message || err.message))
      })
    },

  }
}
</script>

<style lang="scss">


.dashboard-org {
  display: flex;
  flex: 1;

  & > .row {
    & > .col {
      padding: 0;
    }
  }

  .stats {
    flex: unset;
    width: 20.521vw;
    margin-right: 20px;
    & > div {
      height: 100%;
    }

  }

  .car {
    img {
      max-width: 569px;
    }
  }

  .main {

    margin-right: 40px;

    .logo {
      margin-top: 40px;
      margin-left: 40px;
      img {
        width: 50%;
      }
    }


    .bloc {
      &.search {
        .title {
          color: #E53C22;
          font-weight: bold;
          font-size: 22px;
          text-align: center;
        }
        .content {
          margin: 10px 0;
          padding: 10px 0 40px 0;
          .v-text-field--solo {
            .v-input__slot  {
              box-shadow: 0 15px 50px #00000029 !important;
            }
          }
        }
      }

    }

    .bloc {
      &.options {
        .title {
          background-color: #EFEFF0;
          border-radius: 10px 10px 0 0;
          box-shadow: 0px 10px 30px #0000001A;
          height: 60px;
          color: #E53C22;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .content {
          margin-top: 50px;
          .btns {
            display: flex;
            justify-content: center;
            .v-btn {
              display: block;
              color: #758093;
              text-align: center;
              border: 1px solid #758093;
              border-radius: 10px;
              width: 125px;
              height: 125px;
              font-size: 16px;
              font-weight: bold;
              line-height: 20px;
              cursor: pointer;
              white-space: unset;
              text-transform: none;
              letter-spacing: unset;
              .v-btn__content {
                display: flex;
                flex-direction: column;
                line-height: 20px;
              }
              .v-icon {
                display: block !important;
                margin: 10px 0;
              }
              & + .v-btn {
                margin-left: 40px;
              }
            }
          }
        }
      }
    }
  }

  .alerts {
    & > div {
      max-height: calc(100vh - 305px);
    }
    .sort-btn {
      font-weight: bold;
      font-family: Dosis, sans-serif;
      font-size: 16px;
      color: #758093;
      align-self: center;
      .v-btn {
        font-size: 16px;
        background: #ffffff;
        font-weight: bold;
        color: #758093;
        margin-left: 20px;
        border-radius:  35px;
        border: 1px solid #758093;
        &.active {
          background: #00897E;
          border: 1px solid #00897E;
          color: white;
        }
      }
    }

    .v-list {
      background: transparent;
      margin-top: 20px;
      overflow-y: scroll;
      .v-list-item {


        &.active {
          background: white;
          &:after {
            content: '';
            position: absolute;
            left: -2px;
            width: 5px;
            background-color: #E53C22;
            top: 0;
            bottom: 0;
          }
        }

        .date {
          font-weight: bold;
          color: #E53C22;
        }
        .v-list-item__title {
          font-size: 22px;
          font-weight: 500;
          text-transform: uppercase;
          color: #000000;
        }
        .v-list-item__title {
          font-size: 19px;
          font-weight: bold;
          color: #000000;
        }
        .statut {
          font-size: 16px;
        }
        .v-chip {
          width: 100px;
          display: block;
          text-align: center;
          font-weight: bold;
        }
      }
      .v-divider {
        margin: 0 10px;
      }
    }
  }
}

.dashboard-autocomplete {
  .v-list-item {
    padding: 0 20px;
    & + .v-list-item {
      border-top: 1px solid #ccc;
    }
  }
}

</style>