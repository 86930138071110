<template>
  <div class="invitation">
    <div>
      <div class="pa-4 text-center">
        <img src="~@images/logo.svg" class="logo" alt="">
        <v-row class="mb-16">
          <v-col>
            <div class="mt-16" v-if="!loaded">
              <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
              ></v-progress-circular>
            </div>
            <div class="title mt-16" v-else>

              <!-- On a traité l'invitation -->
              <div v-if="done">
                <div v-if="status === 'REJECTED'">{{ $t('member.invitation.rejected') }}</div>
                <div v-else>
                  <v-icon>mdi-check</v-icon>
                  {{ $t('member.invitation.accepted') }}
                </div>
              </div>

              <!-- Invitation expirée -->
              <div v-else-if="invitationHasExpired || status !== 'PENDING'">
                {{ $t('member.invitation.errors.expired') }}
              </div>

              <!-- Token invalide -->
              <div v-else-if="!tokenIsValid">
                {{ $t('member.invitation.errors.invalid-token') }}
              </div>

              <!-- Contenu -->
              <div v-else>
                <div>
                  {{ $t('member.invitation.text', { organizationName: organization }) }}
                </div>

                <v-row class="btns mt-5">
                  <!-- Accept -->
                  <v-col md="6" class="text-right">
                    <v-btn @click="acceptInvitation" rounded outlined color="red" class="white-bg" width="100%" :loading="isSubmitingAccept" :disabled="isSubmitingDecline">
                      <v-icon left>ico-yes</v-icon>
                      {{ $t('member.invitation.accept') }}
                    </v-btn>
                  </v-col>

                  <!-- Refuse -->
                  <v-col md="6" class="text-left">
                    <v-btn @click="declineInvitation" rounded outlined color="grey" class="white-bg" width="100%" :loading="isSubmitingDecline" :disabled="isSubmitingAccept">
                      <v-icon left>ico-no</v-icon>
                      {{ $t('member.invitation.decline') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="car">
          <img src="~@images/car.front.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import RulesMixin from "@mixins/RulesMixin";
  import Validator from "@service/Validator";
  import MemberRepository from "@repository/MemberRepository";

  export default {
    data() {
      return {
        tokenIsValid: false,
        invitationHasExpired: false,
        token: "",
        status: "",
        loaded: false,
        isSubmitingAccept: false,
        isSubmitingDecline: false,
        organization: null,
        done: false, // Si l'invitation a été traitée
      }
    },

    mixins: [
      RulesMixin,
    ],

    mounted() {
      this.checkToken()
    },

    watch: {

    },

    computed: {

    },

    methods: {
      checkToken() {
        const token = decodeURIComponent(this.$route.params.token)

        MemberRepository.checkInvitationStatus(token).then((res) => {
          this.organization = res.data.organization
          this.status = res.data.status
          this.tokenIsValid = true
          this.token = token
          this.loaded = true
        }).catch(e => {
          console.log(e.response.data.message)
          this.tokenIsValid = false

          if((e.response.data.message === 'expired')) {
            this.invitationHasExpired = true
          }

          this.loaded = true
        })
      },

      acceptInvitation() {
        this.isSubmitingAccept = true
        this.isSubmitingDecline = false

        MemberRepository.acceptInvitation(this.token).then((res) => {
          this.isSubmitingAccept = false
          this.done = true
        }).catch(e => {
          this.isSubmitingAccept = false
          this.$dialog.notify.error(e.response?.data?.message || e.message)
        })
      },

      declineInvitation() {
        this.isSubmitingDecline = true
        this.isSubmitingAccept = false

        MemberRepository.declineInvitation(this.token).then((res) => {
          this.isSubmitingDecline = false
          this.checkToken()
          this.done = true
        }).catch(e => {
          this.isSubmitingDecline = false
          this.$dialog.notify.error(e.response?.data?.message || e.message)
        })
      }
    }
  }
</script>


<style lang="scss">
.invitation {
  .logo {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .invitation {
    .car {
      margin-top: 20px;
      img {
        width: 97%;
      }
    }
  }
}
</style>
