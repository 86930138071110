<template>
  <div class="account-edit pa-10">
    <title-page icon="ico-user">
      {{ $t("account.manage-account") }}
    </title-page>

    <v-row class="panel" v-if="user">
      <v-col cols="12" sm="12">

        <v-form class="form-grid" @submit.prevent="save">
          <v-row>
            <v-col>
              <form-field
                  :label="$t('firstname')"
                  v-model="user.firstname"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <form-field
                  :label="$t('lastname')"
                  v-model="user.lastname"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <form-field
                  :label="$t('email')"
                  v-model="user.email"
                  input-type="email"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="label"></v-col>
            <v-col>
              <v-row  class="form-btns">
                <v-col sm="6">
                  <v-btn outlined rounded color="green" type="submit">
                    <v-icon left>mdi-check</v-icon>
                    {{ $t('save') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>


        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import UserRepository from "@repository/UserRepository";

export default {
  data() {
    return {
      user: null,
    }
  },

  mounted() {
    this.user = this.currentUser
  },

  methods: {
    save() {
      this.showLoading(true)
      let data = {
        email: this.user.email,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
      }
      UserRepository.save(data).then(() => {
        this.fetchCurrentUser()
        this.$dialog.notify.success(this.$t('update-success'))
        this.showLoading(false)
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
        this.showLoading(false)
      })
    }
  }
}
</script>

<style lang="scss">
.account-edit {
  .panel {
    padding: 60px;
    align-items: center;
    justify-content: center;
    .shadow-box {
      padding: 20px;
      align-items: center;
      background-image: linear-gradient(159deg, #efeff0, #f8f8f9);
      img {
        width: 200px;
      }
    }
  }
}

</style>