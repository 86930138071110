import Vue from 'vue'

class GireveRepository {


    importTariffs(file, requestId) {
        return new Promise((resolve, reject) => {

            var data = new FormData();
            data.append('file', file)
            data.append('requestId', requestId)

            Vue.auth.fetch({
                url: `/api/gireve/import-tariffs`,
                method: 'post',
                data,
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }


}


export default new GireveRepository()