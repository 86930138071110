<template>
  <div class="login">
    <div class="shadow-box pa-4 text-center">
      <v-form ref="form" @submit.prevent="login" lazy-validation>
        <img src="~@images/logo.svg" class="logo">

        <v-row>
          <v-col>
            <div class="title">
              {{ $t('change-password.title') }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>

            <v-text-field
                v-model="email"
                :label="$t('email')"
                :placeholder="$t('email')"
                persistent-placeholder
                solo
                :rules="[
                    commonRules.email,
                    commonRules.required,
                ]"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>

            <v-text-field
                v-model="password"
                persistent-placeholder
                :label="$t('password')"
                :placeholder="$t('password')"
                :rules="[commonRules.required]"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="showPassword = !showPassword"
                solo
            >
            </v-text-field>

          </v-col>
        </v-row>
        <v-row dense>
          <v-col class="text-right">
            <v-btn :to="{name: 'forgot-password'}" small text class="forgot-password">{{ $t('login.forgot-password') }}</v-btn>
          </v-col>
        </v-row>

        <v-row class="btns">
          <v-col cols="12" class="text-right">
            <v-btn type="submit" rounded outlined color="success" class="white-bg">
              <v-icon left>ico-yes</v-icon>
              {{ $t('login.login') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <div class="car">
        <img src="@images/car.front.png" />
      </div>
    </div>
  </div>
</template>

<script>

import RulesMixin from "@mixins/RulesMixin";

export default {
  components: {

  },
  data() {
    return {
      showPassword: false,
      email: "",
      password: "",
    }
  },

  mixins: [
      RulesMixin,
  ],
  mounted() {

  },

  methods: {
    login() {
      if(this.$refs.form.validate()) {
        this.showLoading(true)
        this.$auth.login({
          data: {
            username: this.email,
            password: this.password,
          },
          rememberMe: true,
        }).then(() => {
          this.$router.replace({name: 'dashboard'})
          this.showLoading(false)
        }).catch(err => {
          this.$dialog.notify.error(err.response?.data?.message || err.message)
          this.showLoading(false)
        })
      }
    }
  }
}
</script>

<style lang="scss">
  .login {
    .shadow-box {
      width: 40.404vw;
      min-width: 400px;
      @media only screen and (max-width: 600px) {
        width: 100%;
        min-width: 100%;
        border: none;
        box-shadow: none;
      }

      .title {
        font-size: 30px;
        color: #5F6569;
        font-weight: 600;
        margin-bottom: 10px;
      }
      form {
        position: relative;
        z-index: 2;
        width: 23.232vw;
        @media only screen and (max-width: 600px) {
          width: 100%;
        }
        padding: 20px;
        align-self: center;
        .logo {
          width: 100%;
        }
      }
      .btns {
        margin-bottom: 20px;
      }
      .forgot-password {
        text-transform: none;
        text-decoration: underline;
        font-size: 16px;
        font-weight: 600;
      }
      .car {
        position: absolute;
        right: 0;
        bottom: 110px;
        z-index: 0;
        @media only screen and (max-width: 600px) {
          display: none;
        }
        img {
          width: 40vw;
        }
      }
    }

  }
</style>