<template>
  <div>
  <div class="register-iban" v-if="tokenIsValid && !registrationSuccess">
    <div class="pa-4 text-center">
      <v-form ref="form" @submit.prevent="save">
        <img src="~@images/logo.svg" class="logo" >
        <v-row>
          <v-col>
            <div class="title mt-16">
              {{ $t('member.iban-registration-request') }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>

              <v-text-field
                  v-model="form.iban"
                  :label="$t('iban.iban')"
                  :placeholder="$t('iban.iban')"
                  :error-messages="form.iban ? ibanErrors : []"
                  persistent-placeholder
                  solo
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>

              <v-text-field
                  v-model="form.bic"
                  persistent-placeholder
                  :label="$t('iban.bic')"
                  :placeholder="$t('iban.bic')"
                  solo
                  :error-messages="form.bic ? bicErrors : []"
              >
              </v-text-field>

            </v-col>
          </v-row>

          <v-row class="btns">
            <v-col cols="12" class="text-right">
              <v-btn type="submit" rounded outlined color="success" class="white-bg" :disabled="!canSubmit">
                <v-icon left>ico-yes</v-icon>
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

      <div class="car">
        <img src="~@images/car.front.png" />
      </div>
    </div>
  </div>
  <div class="register-iban" v-if="!tokenIsValid && !registrationSuccess">
    <div class="pa-4 text-center">
      <img src="~@images/logo.svg" class="logo" >
      <v-row class="mb-16">
        <v-col>
          <div class="title mt-16">
            {{ $t('iban.errors.token-invalid') }}
          </div>
        </v-col>
      </v-row>
      <div class="car">
        <img src="~@images/car.front.png" />
      </div>
    </div>
  </div>
    <div class="register-iban" v-if="registrationSuccess">
      <div class="pa-4 text-center">
        <img src="~@images/logo.svg" class="logo">
        <v-row class="mb-16">
          <v-col>
            <div class="title mt-16">
              {{ $t('member.iban-registration-success') }}
            </div>
          </v-col>
        </v-row>
        <div class="car">
          <img src="~@images/car.front.png"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import MemberRepository from "@repository/MemberRepository";
import Validator from "@service/Validator";
import RulesMixin from "@mixins/RulesMixin";

export default {
  components: {},
  data() {
    return {
      form: {
        "iban": "",
        "bic": ""
      },
      tokenIsValid: false,
      ibanIsValid: false,
      bicIsValid: false,
      registrationSuccess: false
    }
  },

  mixins: [
    RulesMixin,
  ],

  mounted() {
    this.checkToken()
  },

  watch: {
    'form.iban': async function (value) {
      this.ibanIsValid = await Validator.iban(value)
    },
    'form.bic': async function (value) {
      this.bicIsValid = await Validator.bic(value)
    }
  },

  computed: {
    ibanErrors() {
      let errors = []
      if (!this.ibanIsValid) {
        errors.push(this.$t('iban.errors.iban-invalid'))
      }
      return errors
    },
    bicErrors() {
      let errors = []
      if (!this.bicIsValid) {
        errors.push(this.$t('iban.errors.bic-invalid'))
      }
      return errors
    },

    canSubmit() {
      return this.ibanErrors.length === 0 && this.bicErrors.length === 0
    }
  },

  methods: {

    checkToken() {
      MemberRepository.checkRegistrationIbanToken(this.$route.params.token).then(() => {
        this.tokenIsValid = true
        this.form.token = this.$route.params.token
      }).catch(e => {
        this.tokenIsValid = false
      })
    },


    async save() {
      if (this.canSubmit) {
        MemberRepository.saveIban(this.form).then(() => {
          this.registrationSuccess = true
          this.tokenIsValid = false
          this.form.iban = ""
          this.form.bic = ""
        }).catch(err => {

          if (this.$t(`iban.errors.${err.response.data.message}`)) {
            this.$dialog.notify.error(this.$t(`iban.errors.${err.response.data.message}`))
          } else {
            this.$dialog.notify.error(err.response?.data?.message || err.message)
          }

        })

      }

    }
  }
}
</script>

<style lang="scss">
.register-iban {
  .logo {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .register-iban {
    .car {
      margin-top: 20px;
      img {
        width: 100%;
      }
    }
  }
}
</style>
